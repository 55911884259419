import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const BUCKET_URL = "https://all-user-version-4.s3.us-east-1.amazonaws.com/";
const DEFAULT_ICON = "/default-org-icon.png";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    width: "100%",
    padding: theme.spacing(0.5, 0),
    marginBottom: 0, // Ensure no bottom margin
  },
  header: {
    background: "#EBF0F4",
    borderRadius: "8px",
    padding: theme.spacing(1.25),
    width: "100%",
    maxWidth: "100%",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  orgRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  logo: {
    width: 32,
    height: 32,
    background: "#FFFFFF",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      padding: "2px",
    },
  },
  orgName: {
    fontSize: "20px",
    fontWeight: 800,
    color: "#1A202C",
    letterSpacing: "-0.01em",
  },
  subtitle: {
    fontSize: "16px", // Increased size
    fontWeight: 500, // Semi-bold
    color: "#2D3748", // Darker color
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    "& span:not(:first-child)::before": {
      content: '"•"',
      marginLeft: "4px",
      marginRight: "4px",
      color: "#718096",
    },
  },
}));

const Header = ({ organization, lead, pageName }) => {
  const classes = useStyles();

  useEffect(() => {
    if (organization) {
      const iconUrl = organization.iconUrl
        ? `${BUCKET_URL}${organization.iconUrl}`
        : DEFAULT_ICON;

      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = iconUrl;
      link.onerror = () => {
        link.href = DEFAULT_ICON;
      };
      document.head.appendChild(link);
      document.title = organization.name || "Document Viewer";
    }
  }, [organization]);

  if (!organization) return null;

  const fullIconUrl = organization.iconUrl
    ? `${BUCKET_URL}${organization.iconUrl}`
    : DEFAULT_ICON;

  return (
    <div className={classes.headerWrapper}>
      <Container maxWidth="md">
        <div className={classes.header}>
          <div className={classes.mainContent}>
            <div className={classes.orgRow}>
              <Avatar
                src={fullIconUrl}
                alt={organization.name}
                className={classes.logo}
                imgProps={{
                  onError: (e) => {
                    e.target.src = DEFAULT_ICON;
                    console.log("Organization icon fallback loaded");
                  },
                }}
              />
              <Typography className={classes.orgName}>
                {organization.name}
              </Typography>
            </div>

            {(pageName || lead) && (
              <Typography className={classes.subtitle} component="div">
                {pageName && <span>{pageName}</span>}
                {lead && <span>Prepared for {lead}</span>}
              </Typography>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

Header.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string,
    iconUrl: PropTypes.string,
    faviconUrl: PropTypes.string,
  }),
  lead: PropTypes.string,
  pageName: PropTypes.string,
};

export default Header;
