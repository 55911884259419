import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./fonts/Gilroy-Light.ttf";
import "./fonts/Gilroy-Medium.ttf";
import "react-notifications/lib/notifications.css";

// SCREENS
import Justdial from "./Pages/Justdial";
import Indiamart from "./Pages/Indiamart";
import InviteVerification from "./Pages/InviteVerification";
import Zapier from "./Pages/Zapier";
import Facebook from "./Pages/Facebook";
import SharedFile from "./Pages/SharedFile";
import SharedPage from "./Pages/SharedPage";
import SignUp from "./Pages/SignUp";
import Website from "./Pages/Website";
import Wordpress from "./Pages/Wordpress";
import GoogleLeadForm from "./Pages/GoogleLeadForm";
import GoogleForms from "./Pages/GoogleForms";
import Typeform from "./Pages/Typeform";
import TradeIndia from "./Pages/TradeIndia";
import Housing from "./Pages/Housing";
import GoogleAds from "./Pages/GoogleAds";
import Acres from "./Pages/Acres";
import MagicBricks from "./Pages/MagicBricks";
import SharedPageList from "./Pages/SharedPageList";
import BussinessCard from "./Pages/BussinessCard";
import VerifyEmail from "./Pages/verifyEmail";
import GoogleSheets from "./Pages/GoogleSheets";
import Login from "./Pages/login/login";
import OtpVerification from "./Pages/login/otpVerification";
import DeleteAccount from "./Pages/deleteAccount";
import Quotation from "./Pages/Quotation";
import Invoice from "./Pages/Invoice";
import Order from "./Pages/Order";
import GoogleCalenders from "./Pages/GoogleCalenders";
import GoogleMeet from "./Pages/GoogleMeet";
import GoogleContacts from "./Pages/GoogleContacts";
import Gmail from "./Pages/Gmail";
import Wati from "./Pages/Wati";
import Brevo from "./Pages/Brevo";
import Wix from "./Pages/Wix";
import ExporterIndia from "./Pages/ExporterIndia";
import Pabbly from "./Pages/pabbly";
import LinkedInIntegration from './Pages/LinkedInIntegration';

import "./styles.css";
import LocationListener from "./LocationListerner";
import Razorpay from "./Pages/Razorpay";
import OperatorIntegration from "./Pages/OperatorIntegration";

export default () => {
  return (
    <Router>
      <LocationListener />
      <Switch>
        <Route path={["/Quotation/:user_id"]} component={Quotation} />
        <Route path={["/invoice/:user_id"]} component={Invoice} />  
        <Route path={["/order/:user_id"]} component={Order} />  
        <Route path={["/quotation/:user_id"]} component={Quotation} />  
        <Route path={["/integration/justdial/:user_id"]} component={Justdial} />
        <Route path={["/invitation"]} component={InviteVerification} />
        <Route
          path={["/integration/indiamart/:user_id"]}
          component={Indiamart}
        />
        <Route path={["/integration/razorpay/:user_id"]} component={Razorpay} />
        <Route path={["/integration/housing/:user_id"]} component={Housing} />
        <Route
          path={["/integration/GoogleAds/:user_id"]}
          component={GoogleAds}
        />
        <Route path={["/integration/99acres/:user_id"]} component={Acres} />
        <Route
          path={["/integration/magic_bricks/:user_id"]}
          component={MagicBricks}
        />
          <Route
          path={["/integration/OperatorIntegration/:user_id"]}
          component={OperatorIntegration}
        />
        <Route path={["/integration/zapier/:user_id"]} component={Zapier} />
        <Route path={["/integration/facebook/:user_id"]} component={Facebook} />
        <Route
          path={["/integration/wordpress/:user_id"]}
          component={Wordpress}
        />
       <Route
  path={["/integration/linkedin/:user_id"]}
  component={LinkedInIntegration}
/>
        <Route path={["/integration/website/:user_id"]} component={Website} />
        <Route
          path={["/integration/google_lead_form/:user_id"]}
          component={GoogleLeadForm}
        />
        <Route
          path={["/integration/google_meet/:user_id"]}
          component={GoogleMeet}
        />
        <Route
          path={["/integration/google_calendar/:user_id"]}
          component={GoogleCalenders}
        />
        <Route
          path={["/integration/google_contacts/:user_id"]}
          component={GoogleContacts}
        />
        <Route path={["/integration/google_mail/:user_id"]} component={Gmail} />
        <Route
          path={["/integration/google_forms/:user_id"]}
          component={GoogleForms}
        />
        <Route
          path={["/integration/tradeIndia/:user_id"]}
          component={TradeIndia}
        />
        <Route path={["/integration/wati/:user_id"]} component={Wati} />
        <Route path={["/integration/brevo/:user_id"]} component={Brevo} />
        <Route path={["/integration/wix/:user_id"]} component={Wix} />
        <Route
          path={["/integration/exporter_india/:user_id"]}
          component={ExporterIndia}
        />
        <Route path={["/integration/typeform/:user_id"]} component={Typeform} />
        <Route
          path={["/integration/google_spread_sheets/:user_id/"]}
          component={GoogleSheets}
        />
        <Route path={["/integration/pabbly/:user_id"]} component={Pabbly} />
        <Route path={["/file/:uniqueLinkId"]} component={SharedFile} />
        <Route path={["/page/:uniqueLinkId"]} component={SharedPage} />
        <Route path={["/pageList/:uniqueLinkId"]} component={SharedPageList} />
        <Route path={["/invite/:userIdEnc"]} component={SignUp} />
        <Route path={["/bussinessCard"]} component={BussinessCard} />
        <Route path={["/quotation/:user_id"]} component={Quotation} />
        <Route path="/verify-email/:token" component={VerifyEmail} />
        <Route path="/delete-account" component={DeleteAccount} />
        <Route path="/user-login" component={Login} />
        <Route path="/otp-verify" component={OtpVerification} />
      </Switch>
    </Router>
  );
};
