import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";

import { makeStyles } from "@material-ui/core/styles";
import Header from "../Components/Integration Components/Header";
import Integration from "../Components/Integration Components/Integration";
import Logo from "../images/google-ads.jpg";
import Button from "../Components/Integration Components/Button";

import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
} from "../constants/ApiCall";
// UTILS CALL
// import { API_URL } from "../constants/utils";

function GoogleLeadForm(props) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [integration, setIntegration] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [copiedUrl, setCopiedUrl] = useState();
  const [copiedKey, setCopiedKey] = useState();
  const [url, setURL] = useState("");
  // const isPremiumUser =
  //   props.match.params.user_type === "premium" ? true : false;

  const [key, setKey] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [selectedLeadList, setSelectedLeadList] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    connectGoogleLeadForm(token);
  }, []);

  const connectGoogleLeadForm = async (token) => {
    setLoading(true);
    setLoadingText("Connecting...");
    const response = await createUserIntegration("google_lead_form", {}, token);
    console.log(response);
    setLoading(false);
    if (response && response.status) {
      getIntegration();
    }
  };

  const getIntegration = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    console.log("TOKENNNNNNNNNNN" + token);

    const response = await getUserIntegration(token);
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "google_lead_form"
      );
      if (_integration) {
        setIntegration(_integration);
        setKey(_integration.details.apiKey);
        setURL(
          `${process.env.REACT_APP_DEV_API_WEBHOOK_URL}${_integration.integrationKey}`
        );
      }
    }
  };

  // METHODS
  const integrationDone = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setLoadingText("Connecting...");
      let payload = {};
      const response = await createUserIntegration("google-lead-form", payload);
      setLoading(false);
      if (response && response.status) {
        setIntegration(response.data);
        setKey(response.data.details.apiKey);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    const response = await disconnectIntegration(
      integration?.userIntegrationId
    );
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/disconnect/success`,
      });
    } else {
      alert("Error  in integration");
    }
  };
  const handleSelectLeadList = () => {
    return 0;
  };

  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 13px",
      "& .form_lbl": {
        fontFamily: "'Gilroy-SemiBold' !important",
      },
      "& button": {
        width: "330px",
        position: "relative",
        transform: "translateX(-50%)",
        left: "50%",
        marginBottom: "30px !important",
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
    },
    iframe: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
    },
    selectWrap: {
      width: "fit-content",
      height: "40px",
      width: "100%",
      position: "relative",
      "& svg": {
        position: "absolute",
        right: "22px",
        top: "16.5px",
      },
    },
    select: {
      height: "40px",
      padding: "0 15px",
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "600",
      appearance: "none",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
    },
    input: {
      width: "100%",
      minWidth: "331px",
      height: "39px",
      background: "#FFFFFF",
      borderRadius: "10px",
      border: "none",
      padding: "10px 40px 10px 17px !important",
      "&::placeholder": {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"Google Ads leadform"}
            logo={
              <img
                src={Logo}
                alt="Google Ads leadform"
                width="45"
                height="41"
                style={{ borderRadius: "50%" }}
              />
            }
          />
          <div className={classes.text}>
            Connect your Google Ads leadforms and automatically import your form
            Leads instantly.
          </div>
          <div className={classes.text + " mt-4"}>
            How does Google Ads leadsforms work?
          </div>
          
          <div className="mt-4 form_lbl">Webhook URL</div>
          <div className="copyIcon d-flex">
            <input
              type="text"
              className={"mt-2 mb-2 " + classes.input}
              name="APIkey"
              value={url}
            />
            <CopyToClipboard text={url} onCopy={() => setCopiedUrl(true)}>
              {copiedUrl ? (
                <img
                  src={window.location.origin + "/assets/images/checked.ico"}
                  alt="copy-icon"
                  className="icon"
                  style={{ float: "right", marginLeft: "-20px" }}
                />
              ) : (
                <img
                  src={window.location.origin + "/assets/images/copy.png"}
                  alt="copy-icon"
                  style={{ float: "right", marginLeft: "-20px" }}
                />
              )}
            </CopyToClipboard>
          </div>
          <div className="mt-4 form_lbl">API KEY</div>
          <div className="copyIcon d-flex">
            <input
              type="text"
              className={"mt-2 mb-2 " + classes.input}
              name="url"
              value={key}
            />
            <CopyToClipboard text={key} onCopy={() => setCopiedKey(true)}>
              {copiedKey ? (
                <img
                  src={window.location.origin + "/assets/images/checked.ico"}
                  alt="copy-icon"
                  className="icon"
                  style={{ float: "right", marginLeft: "-20px" }}
                />
              ) : (
                <img
                  src={window.location.origin + "/assets/images/copy.png"}
                  style={{ float: "right", marginLeft: "-20px" }}
                />
              )}
            </CopyToClipboard>
          </div>
          <div className="mt-4 form_lbl">Select Custom lead list</div>
          <label className={classes.selectWrap + " mb-5"}>
            <select
              value={selectedLeadList}
              onChange={(e) => handleSelectLeadList(e.target.value)}
              className={classes.select + " mb-5"}
            >
              <option value={""} disabled selected>
                Default lead
              </option>
              {leadData.map((item, i) => {
                return (
                  <option key={i} value={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z"
                fill="black"
              />
            </svg>
          </label>
          {!integration ? (
            <Button onClick={integrationDone}>Connect</Button>
          ) : (
            <Button onClick={handleDisconnectIntegration}>Disconnect</Button>
          )}
        </div>
      </LoadingOverlay>
    </BottomSheet>
  );
}

export default GoogleLeadForm;
