import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import queryString from "query-string";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Header from "../Components/Header";
import { getContent } from "../constants/ApiCall";
import Footer from "../Components/Footer";
import { FileText, Film, ChevronLeft, ChevronRight } from "lucide-react";

const BUCKET_URL = "https://all-user-version-4.s3.us-east-1.amazonaws.com/";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    background: "#FFF",
  },
  main: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  contentSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  viewerWrapper: {
    position: "relative",
    width: "100%",
    background: "#EBEFF4",
    height: "70vh", // Fixed height instead of auto/dynamic
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px", // Added rounded corners
    overflow: "hidden", // Ensure content doesn't overflow rounded corners
  },
  viewerContainer: {
    position: "relative",
    flex: 1,
    width: "100%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mediaContent: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  // Specific styles for PDF
  pdfWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .react-pdf__Document": {
      flex: 1,
      overflow: "auto",
    },
  },
  thumbnailSection: {
    background: "#EBEFF4",
    padding: "10px 0",
    borderRadius: "8px", // Added rounded corners
    overflow: "hidden", // Contains the scroll
  },
  thumbnailStrip: {
    display: "flex",
    overflowX: "auto",
    gap: "8px",
    padding: "0 16px",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "2px",
    },
  },
  thumbnail: {
    width: "80px",
    height: "80px",
    flexShrink: 0,
    border: "2px solid transparent",
    borderRadius: "4px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    cursor: "pointer",
    transition: "border-color 0.2s",
  },
  thumbnailActive: {
    border: "2px solid #4a90e2",
  },
  navButton: {
    position: "absolute",
    width: "40px",
    height: "40px",
    background: "rgba(0, 0, 0, 0.5)",
    border: "none",
    borderRadius: "50%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 10,
    transition: "opacity 0.2s",
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
    "&:hover": {
      opacity: 1,
    },
  },
  descriptionSection: {
    padding: "20px",
    background: "#EBEFF4",
    borderRadius: "8px",
    "& p": {
      margin: 0,
      fontSize: "14px",
      lineHeight: "1.6",
      whiteSpace: "pre-line", // This handles line breaks simply
    },
  },
  userSection: {
    background: "#EBEFF4",
    padding: "20px",
    borderRadius: "4px",
    marginTop: "auto",
    "& .circle-image": {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      objectFit: "cover",
    },
  },
  footerContainer: {
    marginTop: "auto",
    width: "100%",
  },
}));

class ViewerErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error("Viewer Error:", error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4 text-center">
          <h3>Unable to display content</h3>
          <button
            onClick={() => this.setState({ hasError: false })}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Try Again
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

const FileViewer = ({ file }) => {
  const classes = useStyles();
  const [loadError, setLoadError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const fileType = file?.uri?.split(".").pop().toLowerCase();

  useEffect(() => {
    setIsLoading(true);
    setLoadError(null);

    // Reset states on file change
    return () => {
      setIsLoading(false);
      setLoadError(null);
    };
  }, [file?.uri]);

  if (!file?.uri) {
    return <div>No file selected</div>;
  }

  const handleLoadSuccess = () => {
    console.log("File loaded successfully:", file.uri);
    setIsLoading(false);
  };

  const handleLoadError = (error) => {
    console.error("File load error:", error);
    setLoadError(error);
    setIsLoading(false);
  };

  const renderContent = () => {
    if (["jpg", "jpeg", "png", "gif"].includes(fileType)) {
      return (
        <img
          src={file.uri}
          alt="content"
          className={classes.mediaContent}
          onLoadedData={handleLoadSuccess}
          onError={handleLoadError}
        />
      );
    }

    if (["mp4", "webm"].includes(fileType)) {
      return (
        <video
          onLoadedData={handleLoadSuccess}
          onError={handleLoadError}
          controls
          className={classes.mediaContent}
        >
          <source src={file.uri} type={`video/${fileType}`} />
        </video>
      );
    }

    if (fileType === "pdf") {
      return (
        <div className={classes.pdfWrapper}>
          <DocViewer
            documents={[file]}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableHeader: true, // Remove top header
                disableFileName: true, // Remove filename display
              },
              pdfZoom: {
                defaultZoom: 1, // Default zoom level
                zoomJump: 0.2, // Zoom step size
              },
              pdfVerticalScrollByDefault: true, // Enable vertical scrolling by default
              maxZoom: 3, // Limit max zoom
              minZoom: 0.5, // Limit min zoom
            }}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#EBEFF4",
            }}
            className={classes.pdfViewer}
          />
        </div>
      );
    }

    return <div>Unsupported file type</div>;
  };

  return (
    <div className={classes.viewerContainer}>
      {isLoading && <div>Loading...</div>}
      {loadError && <div>Error loading file</div>}
      {!isLoading && !loadError && renderContent()}
    </div>
  );
};

function SharedFile(props) {
  const classes = useStyles();
  const { uniqueLinkId } = props.match.params;

  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [pageContent, setPageContent] = useState({});

  // Auto-scroll thumbnail into view
  useEffect(() => {
    try {
      const thumbnail = document.querySelector(`[data-index="${activeIndex}"]`);
      if (thumbnail) {
        thumbnail.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
        console.log("Scrolled to thumbnail:", activeIndex);
      }
    } catch (err) {
      console.log("Thumbnail scroll error:", err);
    }
  }, [activeIndex]);

  // Load content
  useEffect(() => {
    const loadContent = async () => {
      try {
        const queryParams = queryString.parse(props.location.search);
        const response = await getContent(uniqueLinkId, queryParams.u || "");

        if (response?.data?.content) {
          const myFiles = response.data.content.files.map((file) => ({
            uri: BUCKET_URL + file.filePath,
          }));
          setFiles(myFiles);
          setPageContent(response.data);
        }
      } catch (error) {
        console.error("Content loading error:", error);
      } finally {
        setLoading(false);
      }
    };

    loadContent();
  }, [uniqueLinkId, props.location.search]);

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Loading files..."
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
        },
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.8)",
        }),
      }}
    >
      <div className={classes.root}>
        <CssBaseline />
        <Header
          organization={pageContent?.user?.organization}
          lead={pageContent?.lead}
          pageName={pageContent?.content?.details?.title}
        />
        <Container component="main" className={classes.main} maxWidth="md">
          <div className={classes.contentSection}>
            <ViewerErrorBoundary>
              <div className={classes.viewerWrapper}>
                {files.length > 1 && (
                  <>
                    <button
                      className={classes.navButton}
                      style={{
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      onClick={() =>
                        setActiveIndex((prev) => Math.max(0, prev - 1))
                      }
                      disabled={activeIndex === 0}
                    >
                      <ChevronLeft size={24} />
                    </button>

                    <button
                      className={classes.navButton}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      onClick={() =>
                        setActiveIndex((prev) =>
                          Math.min(files.length - 1, prev + 1)
                        )
                      }
                      disabled={activeIndex === files.length - 1}
                    >
                      <ChevronRight size={24} />
                    </button>
                  </>
                )}
                <div className={classes.viewerContainer}>
                  {files[activeIndex] && (
                    <FileViewer file={files[activeIndex]} />
                  )}
                </div>
              </div>
            </ViewerErrorBoundary>

            {/* Thumbnails */}
            {files.length > 1 && (
              <div className={classes.thumbnailSection}>
                <div className={classes.thumbnailStrip}>
                  {files.map((file, index) => (
                    <div
                      key={index}
                      data-index={index}
                      className={`${classes.thumbnail} ${
                        index === activeIndex ? classes.thumbnailActive : ""
                      }`}
                      onClick={() => setActiveIndex(index)}
                    >
                      {file.uri.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                        <img
                          src={file.uri}
                          alt={`thumbnail ${index}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : file.uri.match(/\.(mp4|webm)$/i) ? (
                        <Film size={30} />
                      ) : (
                        <FileText size={30} />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Description Section */}
            {pageContent?.content?.details?.description && (
              <div className={classes.descriptionSection}>
                <p>{pageContent.content.details.description}</p>
              </div>
            )}

            {/* User info section */}
            {pageContent?.user && (
              <div className={classes.footerContainer}>
                <Footer
                  userData={pageContent.user}
                  pageTitle={pageContent?.content?.details?.title}
                  digitalCard={pageContent?.digitalCard}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </LoadingOverlay>
  );
}

export default SharedFile;
