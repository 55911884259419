import React from "react";
import { Phone, MessageSquare, FileDown } from "lucide-react";

const BUCKET_URL = "https://all-user-version-4.s3.us-east-1.amazonaws.com/";

const styles = {
  footerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  footerArea: {
    background: "#EBEFF4",
    padding: "24px",
    borderRadius: "4px",
    width: "100%",
  },

  sharedByText: {
    fontSize: "16px",
    color: "#666",
    marginBottom: "15px",
    fontWeight: "800",
    textAlign: "center",
  },

  userInfoSection: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    padding: "0 0 24px 0",
    borderBottom: "1px solid rgba(0,0,0,0.08)",
    marginBottom: "24px",
  },

  profileImage: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "2px solid white",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },

  userDetails: {
    flex: 1,
    paddingTop: "4px",
  },

  userName: {
    margin: "0 0 4px 0",
    fontSize: "18px",
    fontWeight: "800",
    color: "#333",
  },

  userMeta: {
    display: "block",
    margin: "4px 0",
    fontSize: "16px",
    color: "#666",
  },

  actionButton: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "16px",
    textDecoration: "none",
    borderRadius: "8px",
    color: "#333",
    marginBottom: "12px",
    transition: "all 0.3s ease-in-out",
    cursor: "pointer",
    border: "1px solid rgba(0,0,0,0.05)",
  },

  buttonIcon: {
    display: "flex",
    alignItems: "center",
    width: "20px",
    height: "20px",
    justifyContent: "center",
  },

  buttonText: {
    flex: 1,
    fontSize: "14px",
    fontWeight: "500",
  },

  brandFooter: {
    marginTop: "24px",
    paddingTop: "20px",
    borderTop: "1px solid rgba(0,0,0,0.08)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  brandLink: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    textDecoration: "none",
    color: "#666",
    padding: "8px 16px",
    borderRadius: "20px",
    background: "rgba(0,0,0,0.03)",
    transition: "all 0.3s ease-in-out",
  },
};

const buttonStyles = {
  call: {
    background:
      "linear-gradient(to right, rgba(76, 175, 80, 0.08), rgba(255, 255, 255, 0))",
  },
  sms: {
    background:
      "linear-gradient(to right, rgba(33, 150, 243, 0.08), rgba(255, 255, 255, 0))",
  },
  whatsapp: {
    background:
      "linear-gradient(to right, rgba(37, 211, 102, 0.08), rgba(255, 255, 255, 0))",
  },
  download: {
    background:
      "linear-gradient(to right, rgba(244, 67, 54, 0.08), rgba(255, 255, 255, 0))",
  },
};

const iconColors = {
  phone: "#4CAF50",
  sms: "#2196F3",
  download: "#F44336",
};

const Footer = ({ userData, pageTitle, digitalCard }) => {
  const { phone, firstName, lastName, profile, company_name } = userData || {};

  const whatsappNumber = phone?.replace(/\D/g, "");
  const whatsappMessage = encodeURIComponent(
    `Hello, I'm interested in ${pageTitle || "your shared content"}`
  );

  return (
    <div style={styles.footerContainer}>
      <div style={styles.footerArea}>
        <p style={styles.sharedByText}>Shared by</p>
        <div style={styles.userInfoSection}>
          <img
            src={
              profile
                ? `${BUCKET_URL}${profile.filePath}`
                : "/assets/images/6.png"
            }
            alt={`${firstName} ${lastName}`}
            style={styles.profileImage}
            onError={(e) => (e.target.src = "/assets/images/6.png")}
          />
          <div style={styles.userDetails}>
            <h5 style={styles.userName}>
              {firstName} {lastName}
            </h5>
            <span style={styles.userMeta}>{phone}</span>
            <span style={styles.userMeta}>{company_name}</span>
          </div>
        </div>

        <a
          href={`tel:${phone}`}
          style={{ ...styles.actionButton, ...buttonStyles.call }}
          onMouseOver={(e) => (e.target.style.transform = "scale(1.02)")}
          onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
        >
          <span style={styles.buttonIcon}>
            <Phone size={20} color={iconColors.phone} />
          </span>
          <span style={styles.buttonText}>Call</span>
        </a>

        <a
          href={`sms:${phone}`}
          style={{ ...styles.actionButton, ...buttonStyles.sms }}
          onMouseOver={(e) => (e.target.style.transform = "scale(1.02)")}
          onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
        >
          <span style={styles.buttonIcon}>
            <MessageSquare size={20} color={iconColors.sms} />
          </span>
          <span style={styles.buttonText}>Send SMS</span>
        </a>

        <a
          href={`https://wa.me/${whatsappNumber}?text=${whatsappMessage}`}
          style={{ ...styles.actionButton, ...buttonStyles.whatsapp }}
          onMouseOver={(e) => (e.target.style.transform = "scale(1.02)")}
          onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={styles.buttonIcon}>
            <img
              src="/assets/images/whatsappGreen.png"
              alt="WhatsApp"
              style={{ width: "20px", height: "20px", objectFit: "contain" }}
            />
          </span>
          <span style={styles.buttonText}>WhatsApp</span>
        </a>

        {digitalCard?.cardUrl && (
          <a
            href={`${BUCKET_URL}${digitalCard.cardUrl}`}
            style={{ ...styles.actionButton, ...buttonStyles.download }}
            onMouseOver={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <span style={styles.buttonIcon}>
              <FileDown size={20} color={iconColors.download} />
            </span>
            <span style={styles.buttonText}>Download Digital Card</span>
          </a>
        )}

        <div style={styles.brandFooter}>
          <a
            href="https://www.3sigmacrm.com"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.brandLink}
            onMouseOver={(e) => {
              e.currentTarget.style.background = "rgba(0,0,0,0.06)";
              e.currentTarget.style.color = "#000";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.background = "rgba(0,0,0,0.03)";
              e.currentTarget.style.color = "#666";
            }}
          >
            <img
              src="/assets/images/YellowLogo.png"
              alt="3Sigma Logo"
              style={{ height: "20px", width: "auto" }}
            />
            <span style={{ fontSize: "14px", margin: 0, fontWeight: "500" }}>
              Powered by 3Sigma
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
