import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { makeStyles } from "@material-ui/core";
import { CheckCircle2, XCircle, AlertCircle, Timer } from "lucide-react";

function InviteVerification() {
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Processing invitation");
  const [status, setStatus] = useState("processing"); // processing, success, error, declined
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    console.log("Processing invitation verification");
    verifyInvite();
  }, []);

  useEffect(() => {
    if (!loading) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            window.close();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [loading]);

  const verifyInvite = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const status = urlParams.get('status');

      console.log("Invitation params:", { code, status });

      if (!code || !status) {
        setStatus("error");
        NotificationManager.error("Invalid invitation link");
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}user/verify-invite?code=${code}&status=${status}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      console.log("API Response status:", response.status);

      if (response.status === 200) {
        setStatus(status === 'accepted' ? 'success' : 'declined');
        NotificationManager.success(
          status === 'accepted' 
            ? "Invitation accepted successfully" 
            : "Invitation declined successfully"
        );
      } else {
        setStatus("error");
        NotificationManager.error("Failed to process invitation");
      }
    } catch (error) {
      console.error("Verification error:", error);
      setStatus("error");
      NotificationManager.error("Error processing invitation");
    }
    setLoading(false);
  };

  const useStyles = makeStyles(() => ({
    container: {
      padding: "20px",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f5f5f5",
      "& .form_lbl": {
        fontFamily: "'Gilroy-SemiBold' !important",
      }
    },
    card: {
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "40px 30px",
      maxWidth: "90%",
      width: "500px",
      margin: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "@media (max-width: 600px)": {
        padding: "30px 20px",
        width: "100%",
      }
    },
    title: {
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "1.2",
      color: "#000000",
      marginBottom: "20px",
      textAlign: "center",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      }
    },
    text: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#666666",
      textAlign: "center",
      marginBottom: "10px",
      "@media (max-width: 600px)": {
        fontSize: "14px",
      }
    },
    icon: {
      marginBottom: "25px",
      "@media (max-width: 600px)": {
        transform: "scale(0.9)",
      }
    },
    countdownContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginTop: "20px",
      color: "#666666",
      fontSize: "14px",
    }
  }));

  const classes = useStyles();

  const getStatusContent = () => {
    switch(status) {
      case 'success':
        return {
          icon: <CheckCircle2 className={classes.icon} size={80} color="#4CAF50" />,
          title: "Invitation Accepted",
          message: "Thank you for accepting the invitation. You will be automatically redirected to continue.",
        };
      case 'declined':
        return {
          icon: <XCircle className={classes.icon} size={80} color="#FF5722" />,
          title: "Invitation Declined",
          message: "You have declined the invitation. This window will close automatically.",
        };
      case 'error':
        return {
          icon: <AlertCircle className={classes.icon} size={80} color="#f44336" />,
          title: "Invalid Invitation",
          message: "This invitation link is invalid or has expired. Please contact the sender for a new invitation.",
        };
      default:
        return {
          icon: null,
          title: "Processing",
          message: loadingText,
        };
    }
  };

  const statusContent = getStatusContent();

  return (
    <LoadingOverlay active={loading} spinner text={loadingText}>
      <div className={"container integration_container " + classes.container}>
        <div className={classes.card}>
          {!loading && statusContent.icon}
          <div className={classes.title}>
            {statusContent.title}
          </div>
          <div className={classes.text}>
            {statusContent.message}
          </div>
          {!loading && (
            <div className={classes.countdownContainer}>
              <Timer size={16} />
              <span>Closing in {countdown} seconds</span>
            </div>
          )}
        </div>
      </div>
      <NotificationContainer />
    </LoadingOverlay>
  );
}

export default InviteVerification;